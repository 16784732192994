import React, { useEffect, useState } from "react";
import { Link } from "gatsby"
import Slider from "react-slick";
import _ from "lodash"
import axios from 'axios';
import $ from "jquery"
import { Container, Row, Col, Form } from "react-bootstrap"
import NoImage from "../../../images/no-image.jpg"

import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import "./Property.scss"
const settings = {
    speed: 800,
    infinite: true,
    arrows: false,
    mobileFirst: true,
    centerMode: false,
    responsive: [
        {
            breakpoint: 3020,
            settings: {
                slidesToShow: 3,
                dots: false,


            }
        },
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                dots: false,

            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                dots: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                dots: true,

            }
        }
    ]
};
const Property = (props) => {
    const [tabActive, setIsActive] = useState(false)
    const tabbing1 = () => setIsActive(false);
    const tabbing2 = () => setIsActive(true);
    const [iconOne, setOne] = useState(false);
    const [iconTwo, setTwo] = useState(false);
    const [iconThree, setThree] = useState(false);
    const [wishlistproperties, setWishlistProperties] = useState([]);
    const [loginstatus, setLoginStatus] = useState('')
    const [savetype, setSaveType] = useState(false);
    const [addresponse, setAddResponse] = useState('')
    const [wish, setWish] = useState('')

    const getwishlistproperty = url => {
        fetch(url, {
            method: 'GET',
            // mode: "no-cors",
            redirect: 'follow',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                "Content-Type": "application/json",
            },
            credentials: 'include'
        }).then(response => response.text())
            .then(result => {
                setWishlistProperties(result);
            })
            .catch(error => console.log('error', error));
    }

    const handleFirst = (id) => {
        // setSecond(!second);
        var className = $(`#${id}`).attr('class');
        if (className == "icon-heart") {
            $(`#${id}`).removeClass('icon-heart').addClass('icon-heart-fill');
            wishlistpropertyadd(`https://myaccount.wardsofkent.co.uk/api/propertyshortlist/${id}`, "add")
            setSaveType(true)
        }
        if (className == "icon-heart-fill") {
            $(`#${id}`).removeClass('icon-heart-fill').addClass('icon-heart');
            wishlistpropertyadd(`https://myaccount.wardsofkent.co.uk/api/propertyshortlist/${id}`, "remove")
            setSaveType(false)

        }
    }


    const wishlistpropertyadd = async (url, type) => {

        var raw = JSON.stringify("remove");
        try {
            fetch(url, {
                method: 'PUT',
                // mode: "no-cors",
                redirect: 'follow',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                    "Content-Type": "application/json",
                },
                body: type == "remove" ? raw : '',
                credentials: 'include'
            }).then(response => response.text())
                .then(result => { setAddResponse(result) })
                .catch(error => console.log('error', error));

            // setLoginStatus(data.text())
        } catch (error) {
            console.error(error)
        }

    }



    const [propItems, setPropItems] = useState(false)
    const [propItemsLet, setPropItemsLet] = useState(false)
    const getitems = async url => {
        try {
            const { data } = await axios.get(url, {})
            setPropItems(data)
        } catch (error) {
            console.error(error)
        }
    }
    const getitemslet = async url => {
        try {
            const { data } = await axios.get(url, {})
            setPropItemsLet(data)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        let area = props.area ?? "";
        let url = process.env.GATSBY_STRAPI_FEED_SRC + `/properties?search_type=sales&department=residential&_where[publish]=true&[brand_id]=${process.env.GATSBY_STRAPI_BRAND_ID}&_limit=8&_sort=published_at:DESC`; // TODO: base URL supposed to be from .env
        if (area) {
            url = url + "&display_address_contains=" + area;
        }
        let url_let = process.env.GATSBY_STRAPI_FEED_SRC + `/properties?search_type=lettings&department=residential&_where[publish]=true&[brand_id]=${process.env.GATSBY_STRAPI_BRAND_ID}&_limit=8&_sort=published_at:DESC`; // TODO: base URL supposed to be from .env
        if (area) {
            url_let = url_let + "&display_address_contains=" + area;
        }

        getitems(url)
        getitemslet(url_let)
        setLoginStatus(localStorage.getItem('myaccount-login-status'))

        if (localStorage.getItem('myaccount-login-status')) {
            getwishlistproperty(`https://myaccount.wardsofkent.co.uk/api/propertyshortlist/`)
        }
    }, [])
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''

    return (
        <React.Fragment>
            {propItems.length > 0 || propItemsLet.length > 0 ?
                <InView  {...inViewOptions}>
                    {({ ref, inView }) => (
                        <motion.section
                            className="property"
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={contentItemStagger}

                        >
                            <Container>
                                <Row>
                                    <Col >
                                        <div className="title d-none d-lg-block">
                                            <span className="sm-heading">Showcased properties in {props.Name}</span>
                                            <h2>If you can see yourself living in {props.Name}, browse some of the best properties the area has to offer.</h2>
                                            <div className="btn-wrapper">
                                                <button type="button" className={tabActive ? 'btn' : ' btn active-tab'} onClick={tabbing1}>
                                                    Property for Sale in {props.Name}
                                                </button>
                                                {propItemsLet.length > 0 &&
                                                    <button type="button" className={tabActive ? 'btn active-tab' : ' btn'} onClick={tabbing2}>
                                                        Property to Rent in {props.Name}
                                                    </button>
                                                }
                                            </div>

                                        </div>
                                        <h2 className="d-lg-none">Similar Properties near{props.Name}</h2>
                                        <div className={tabActive ? 'd-none' : ''}>
                                            <Slider {...settings} >
                                                {propItems && propItems.map((item, index) => {
                                                    let uriStr = "";

                                                    if (item.search_type === "sales") {
                                                        uriStr = `property-for-sale/`
                                                    } else if (item.search_type === "lettings") {
                                                        uriStr = `property-to-rent/`
                                                    }

                                                    const img_url = item.images[0].url

                                                    // ggfx
                                                    let processedImages = JSON.stringify({});
                                                    if (item?.imagetransforms?.images_Transforms) {
                                                        processedImages = item.imagetransforms.images_Transforms;
                                                    }
                                                    // ggfx
                                                    var wish = ''
                                                    if (_.includes(wishlistproperties, item.crm_id)) {
                                                        wish = 'true'
                                                    }

                                                    return (
                                                        <motion.div key={index} custom={index + 1} variants={fadeInFromTop} className="property-card-list">
                                                            <div className="properties-wrapper ">
                                                                <Link to={'/' + uriStr + item.slug + '-' + item.crm_id}>
                                                                    <div className="property-img img-zoom">
                                                                        <picture>
                                                                            {img_url ?
                                                                                <ImageTransform
                                                                                    imagesources={img_url}
                                                                                    renderer="srcSet"
                                                                                    imagename="property.images.detail"
                                                                                    attr={{ alt: '', class: '' }}
                                                                                    imagetransformresult={processedImages}
                                                                                    id={item.id}
                                                                                    ggfxName="FEED"
                                                                                /> : <img src={NoImage} className="image-fit-cover" alt="" />}
                                                                            {/* <img src={NoImage} className="image-fit-cover" alt="" /> */}
                                                                        </picture>
                                                                    </div>
                                                                </Link>

                                                                <div className="properties-info">
                                                                    <h2 className="heading-info d-flex justify-content-between sim-head-h2" >
                                                                        <Link to={'/' + uriStr + item.slug + '-' + item.crm_id}>{item.display_address ? item.display_address : item.address.display_address}</Link>
                                                                        {localStorage.getItem('myaccount-login-status') == "true" ?
                                                                            <a href="javascript:void(0);" onClick={() => { wish = ''; handleFirst(item.crm_id); }}><i id={item.crm_id} className={wish ? "icon-heart-fill" : "icon-heart"}></i></a> :
                                                                            <a href={`https://myaccount.wardsofkent.co.uk/Account/MainSiteLogin/?returnUrl=${pageurl}&id=${item.crm_id}`}>
                                                                                <i id={item.crm_id} className="icon-heart"></i>
                                                                            </a>
                                                                        }
                                                                    </h2>
                                                                    <span className="price">£{item.price && item.price.toLocaleString()} {item.search_type == "lettings" ? ' PCM' : ''}</span>
                                                                    <span className="property-price"></span>
                                                                    <span className="info-text">{item.title.replace("-", "")}</span>
                                                                    <div className="properties-icons">
                                                                        <span className="text">
                                                                            <a href="javascript:void(0)">
                                                                                <i className="icon-bedroom"></i>
                                                                            </a>
                                                                            <span className="sm-text">{item.bedroom}</span>
                                                                        </span>
                                                                        <span className="text">
                                                                            <a href="javascript:void(0)">
                                                                                <i className="icon-bath"></i>
                                                                            </a>
                                                                            <span className="sm-text">{item.bathroom}</span>
                                                                        </span>
                                                                        <span className="text">
                                                                            <a href="javascript:void(0)">
                                                                                <i className="icon-sofa"></i>
                                                                            </a>
                                                                            <span className="sm-text">{item.reception}</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="btn-group-wrap">
                                                                <button className="btn-camera"><i className="icon-camera-gallery"></i> <span>1/{item.images.length}</span></button>
                                                                {item.virtual_tour.length > 0 &&
                                                                    <button className="btn-play-circle"><i className="icon-play-circle"></i></button>
                                                                }
                                                                {item.floorplan.length > 0 &&
                                                                    <button className="btn-flor"><i className="icon-flor"></i> </button>
                                                                }
                                                            </div>
                                                        </motion.div>
                                                    )
                                                })
                                                }

                                            </Slider>
                                        </div>
                                        <div className={!tabActive ? 'd-none' : ''}>
                                            {propItemsLet.length > 0 &&
                                                <Slider {...settings} >
                                                    {propItemsLet && propItemsLet.map((item, index) => {
                                                        let uriStr = "";

                                                        if (item.search_type === "sales") {
                                                            uriStr = `property-for-sale/`
                                                        } else if (item.search_type === "lettings") {
                                                            uriStr = `property-to-rent/`
                                                        }

                                                        const img_url = item.images[0].url

                                                        // ggfx
                                                        let processedImages = JSON.stringify({});
                                                        if (item?.imagetransforms?.images_Transforms) {
                                                            processedImages = item.imagetransforms.images_Transforms;
                                                        }
                                                        // ggfx
                                                        var wish = ''
                                                        if (_.includes(wishlistproperties, item.crm_id)) {
                                                            wish = 'true'
                                                        }

                                                        return (
                                                            <motion.div key={index} custom={index + 1} variants={fadeInFromTop} className="property-card-list">
                                                                <div className="properties-wrapper ">
                                                                    <Link to={'/' + uriStr + item.slug + '-' + item.crm_id}>
                                                                        <div className="property-img img-zoom">
                                                                            <picture>
                                                                                {img_url ?
                                                                                    <ImageTransform
                                                                                        imagesources={img_url}
                                                                                        renderer="srcSet"
                                                                                        imagename="property.images.detail"
                                                                                        attr={{ alt: '', class: '' }}
                                                                                        imagetransformresult={processedImages}
                                                                                        id={item.id}
                                                                                        ggfxName="FEED"
                                                                                    /> : <img src={NoImage} className="image-fit-cover" alt="" />}
                                                                                {/* <img src={NoImage} className="image-fit-cover" alt="" /> */}
                                                                            </picture>
                                                                        </div>
                                                                    </Link>

                                                                    <div className="properties-info">
                                                                        <h2 className="heading-info d-flex justify-content-between sim-head-h2" >
                                                                            <Link to={'/' + uriStr + item.slug + '-' + item.crm_id}>{item.display_address ? item.display_address : item.address.display_address}</Link>
                                                                            {localStorage.getItem('myaccount-login-status') == "true" ?
                                                                                <a href="javascript:void(0);" onClick={() => { wish = ''; handleFirst(item.crm_id); }}><i id={item.crm_id} className={wish ? "icon-heart-fill" : "icon-heart"}></i></a> :
                                                                                <a href={`https://myaccount.wardsofkent.co.uk/Account/MainSiteLogin/?returnUrl=${pageurl}&id=${item.crm_id}`}>
                                                                                    <i id={item.crm_id} className="icon-heart"></i>
                                                                                </a>
                                                                            }
                                                                        </h2>
                                                                        <span className="price">£{item.price && item.price.toLocaleString()} {item.search_type == "lettings" ? ' PCM' : ''}</span>
                                                                        <span className="property-price"></span>
                                                                        <span className="info-text">{item.title.replace("-", "")}</span>
                                                                        <div className="properties-icons">
                                                                            <span className="text">
                                                                                <a href="javascript:void(0)">
                                                                                    <i className="icon-bedroom"></i>
                                                                                </a>
                                                                                <span className="sm-text">{item.bedroom}</span>
                                                                            </span>
                                                                            <span className="text">
                                                                                <a href="javascript:void(0)">
                                                                                    <i className="icon-bath"></i>
                                                                                </a>
                                                                                <span className="sm-text">{item.bathroom}</span>
                                                                            </span>
                                                                            <span className="text">
                                                                                <a href="javascript:void(0)">
                                                                                    <i className="icon-sofa"></i>
                                                                                </a>
                                                                                <span className="sm-text">{item.reception}</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="btn-group-wrap">
                                                                    <button className="btn-camera"><i className="icon-camera-gallery"></i> <span>1/{item.images.length}</span></button>
                                                                    {item.virtual_tour.length > 0 &&
                                                                        <button className="btn-play-circle"><i className="icon-play-circle"></i></button>
                                                                    }
                                                                    {item.floorplan.length > 0 &&
                                                                        <button className="btn-flor"><i className="icon-flor"></i> </button>
                                                                    }
                                                                </div>
                                                            </motion.div>
                                                        )
                                                    })
                                                    }

                                                </Slider>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </motion.section>
                    )}
                </InView>
                : ''}
        </React.Fragment>
    )
}
export default Property
